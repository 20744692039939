import React, { useState, useEffect, useReducer } from "react";
// import { useStoreState } from '../../common/storeContext'
import { GAME_MENU_DATA } from '../../common/constants'
import { Container, Col } from "react-bootstrap";
import { TENANT_BUCKET } from '../../common/constants'
import { Link } from "react-router-dom";
import { useMiddletier } from "../../common/middletier";
// import { AiOutlineRight } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../../common/storeContext'

import Loading from '../../components/Loading';
import TransferBalanceDialog from '../../components/TransferBalanceDialog'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation, Grid } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./template_01.css";


const GAME_ACTION = {
    SET_GAME_TYPES: 'SET_GAME_TYPES',
    SET_SELECTED_GAME_TYPE: 'SET_SELECTED_GAME_TYPE',
    SET_GAME_PROVIDERS: 'SET_GAME_PROVIDERS',
    SET_SELECTED_SET_GAME_PROVIDERS: 'SET_SELECTED_SET_GAME_PROVIDERS',
}

const initialGameData = {
    gameTypes: [],
    selectedGameType: {},
    gameProviders: [],
    selectedGameProviders: [],
    // fillCount: 0
}

const gameReducer = (state, action) => {
    switch (action.type) {

        case GAME_ACTION.SET_GAME_TYPES: {
            const gameTypes = action.payload

            return { ...state, gameTypes, selectedGameType: gameTypes?.length > 0 ? { ...gameTypes[0] } : { code: '', name: '' } };
        }

        case GAME_ACTION.SET_SELECTED_GAME_TYPE: {
            const selectedGameType = state.gameTypes.find((gameTypes) => gameTypes.code === action.payload)
            return { ...state, selectedGameType };
        }

        case GAME_ACTION.SET_GAME_PROVIDERS: {
            const gameProviders = [...action.payload]
            return { ...state, gameProviders };
        }

        case GAME_ACTION.SET_SELECTED_SET_GAME_PROVIDERS: {
            const selectedGameProviders = [...action.payload]
            return { ...state, selectedGameProviders };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

const MainGame = (props) => {
    const appState = useStoreState()
    const appDispatch = useStoreDispatch()

    const { query, queries, mutation } = useMiddletier();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })
    const { desktopToggle, mobileToggle, imageToggle, titleToggle, position, imageSize, rowMobile, rowDesktop, folderImage, gameProviderNameToggle, menuPosition, sliderToggle, sliderRow, navigation, speed, pagination, autoplay,autoMenuWidth } = props;
    const [transferDialogShow, setTransferDialogShow] = useState({});
    const [isLoading, setLoading] = useState({ show: false, message: null });
    const { t, i18n } = useTranslation();
    const [gameState, gameDispatch] = useReducer(gameReducer, initialGameData)
    const widthColumn = isDesktopOrLaptop ? (rowDesktop ? 100 / rowDesktop : 0) : (rowMobile ? 100 / rowMobile : 0)
    const [deviceType, setDeviceType] = useState('');
    const [isInAppBrowser, setIsInAppBrowser] = useState(false);
    const [isLoadingTransfer, setLoadingTransfer] = useState(false);
    const speeds = 2.5 ? 2.5 * 1000 : 3000;

    const paginations = {
        clickable: true,
    };

    const autoplays = {
        delay: speeds,
        disableOnInteraction: false,
    }


    // const isSafari = () => /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);

    const onChangeGameType = (newValue) => {
        gameDispatch({ type: GAME_ACTION.SET_SELECTED_GAME_TYPE, payload: newValue })
    }



    const onClickGameProvider = (gameProvider) => {
        if (appState.loggedIn) {
            // console.log('isSafari', isSafari())

            setLoading({ show: true, message: null });
            // setLoading({ show: true, message: 'Popups are required for this feature. Please check your settings.' });
            // -----------------------------------------------
            let mytab
            if (!isInAppBrowser) {
                // OPEN NEW TAB FOR GAME
                mytab = window.open("/loading.html", "_blank");
            }

            // IF GAME PROVIDER AUTO TRANSFER AFTER GAME LINK TRUE (RESTORE FIRST THEN CALL GET GAME LINK)
            if (gameProvider.auto_transfer_after_game_link) {
                mutation([{
                    method: 'restoreGameWalletByMember',
                    params: [
                        { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
                        { code: 'gameProvider', graphqlType: 'String', required: true, value: gameProvider.game_provider },
                        { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
                    ],
                    attributes: []

                }])
                    .then(({ data }) => {

                        // mytab.location = '/loading_transfer.html'
                        if (!isInAppBrowser) {
                            mytab.location = '/loading_transfer.html'
                        } else {
                            setLoading(false);
                            setLoadingTransfer(true);
                        }

                        query({
                            method: 'getGameLinkByMember',
                            params: [
                                { code: 'gameProvider', graphqlType: 'String', required: true, value: gameProvider.game_provider },
                                { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
                                { code: 'gameType', graphqlType: 'String', required: true, value: gameState.selectedGameType?.code },
                                { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
                                { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
                            ],
                            attributes: []

                        }).then(({ data }) => {

                            const gameBalance = data['getGameLinkByMember'].balance
                            const gameUrl = data['getGameLinkByMember'].gameLink
                            const gameWalletAmount = data['getGameLinkByMember'].wallet_amount

                            // currentGame.getCurrentGameUrl = gameUrl

                            if (gameUrl === "") {
                                setLoading(false);
                                // mytab.close()
                                if (!isInAppBrowser) {
                                    mytab.close()
                                } else {
                                    setLoadingTransfer(false);
                                }
                                const getResponse = JSON.parse(data['getGameLinkByMember'].response)
                                const getResponseErrorMsg = getResponse?.errMsg

                                appDispatch({
                                    type: APP_STORE_ACTION.SHOW_ALERT,
                                    payload: { description: getResponseErrorMsg, typeAlert: 'error' }
                                });

                                return
                            } else {

                                // mytab.location = gameUrl
                                if (!isInAppBrowser) {
                                    mytab.location = gameUrl
                                } else {
                                    setLoadingTransfer(false);
                                }

                                if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                                    setLoading(false);
                                    setLoadingTransfer(false);
                                    setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: { ...gameProvider, getCurrentGameType: gameState.selectedGameType?.code, getCurrentGameProvider: gameProvider.game_provider }, walletAmount: gameWalletAmount });
                                }

                                else {

                                    if (isInAppBrowser) {
                                        window.location.href = gameUrl;
                                    }
                                    queries([{
                                        index: 'getAllGamePrivoderBalanceByMember',
                                        method: 'getAllGamePrivoderBalanceByMember',
                                        params: [],
                                        attributes: []
                                    },
                                    {
                                        index: 'member',
                                        method: 'member',
                                        params: [
                                            { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                                        ],
                                        attributes: ['wallet_amount']
                                    },])
                                        .then(({ data }) => {

                                            setLoading(false);
                                            setLoadingTransfer(false);
                                            appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                                            appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })


                                        }).catch((error) => {
                                            // console.log('error: ', error)
                                            setLoading(false)
                                            if (error?.networkError?.statusCode === 401) {
                                                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                                            } else {
                                                appDispatch({
                                                    type: APP_STORE_ACTION.SHOW_ALERT,
                                                    payload: { description: error.message.toString(), typeAlert: 'error' }
                                                });
                                            }
                                        })
                                }


                            }

                        }).catch((error) => {
                            // mytab.close()
                            if (!isInAppBrowser) {
                                mytab.close()
                            }
                            setLoading(false);
                            setLoadingTransfer(false);

                            // console.log('error: ', error)
                            if (error?.networkError?.statusCode === 401) {
                                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                            } else {
                                appDispatch({
                                    type: APP_STORE_ACTION.SHOW_ALERT,
                                    payload: { description: error.message.toString(), typeAlert: 'error' }
                                });
                            }
                        })

                    })
                    .catch((error) => {
                        // console.log('error: ', error)
                        // mytab.close()
                        if (!isInAppBrowser) {
                            mytab.close()
                        }
                        setLoading(false)
                        setLoadingTransfer(false);

                        if (error?.networkError?.statusCode === 401) {
                            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                        } else {
                            appDispatch({
                                type: APP_STORE_ACTION.SHOW_ALERT,
                                payload: { description: error.message.toString(), typeAlert: 'error' }
                            });
                        }
                    })
            } else {
                let method = 'getGameLinkByMember';
                let params = [
                    { code: 'gameProvider', graphqlType: 'String', required: true, value: gameProvider.game_provider },
                    { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
                    { code: 'gameType', graphqlType: 'String', required: true, value: gameState.selectedGameType?.code },
                    { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
                    { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
                ];

                query({
                    method,
                    params,
                    attributes: []

                }).then(({ data }) => {

                    // console.log('getGameLinkByMember', data[method])
                    const gameBalance = data[method].balance
                    const gameUrl = data[method].gameLink
                    const gameWalletAmount = data[method].wallet_amount
                    // mytab.location = gameUrl

                    if (gameUrl === "") {
                        setLoading(false);
                        // mytab.close()
                        if (!isInAppBrowser) {
                            mytab.close()
                        } else {
                            setLoadingTransfer(false);
                        }
                        const getResponse = JSON.parse(data[method].response)
                        const getResponseErrorMsg = getResponse?.errMsg

                        appDispatch({
                            type: APP_STORE_ACTION.SHOW_ALERT,
                            payload: { description: getResponseErrorMsg, typeAlert: 'error' }
                        });

                        return
                    } else {
                        // mytab.location = '/loading_transfer.html'
                        if (!isInAppBrowser) {
                            mytab.location = '/loading_transfer.html'
                        } else {
                            setLoading(false);
                            setLoadingTransfer(true);
                        }

                        mutation([{
                            method: 'restoreGameWalletByMember',
                            params: [
                                { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
                                { code: 'gameProvider', graphqlType: 'String', required: true, value: gameProvider.game_provider },
                                { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
                            ],
                            attributes: []

                        }])
                            .then(({ data }) => {

                                // mytab.location = gameUrl
                                if (!isInAppBrowser) {
                                    mytab.location = gameUrl
                                } else {
                                    setLoadingTransfer(false);

                                }
                                if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                                    setLoading({ show: false, message: null });
                                    setLoadingTransfer(false);
                                    setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: { ...gameProvider, getCurrentGameType: gameState.selectedGameType?.code, getCurrentGameProvider: gameProvider.game_provider }, walletAmount: gameWalletAmount });
                                }

                                else {

                                    if (isInAppBrowser) {
                                        window.location.href = gameUrl;
                                    }
                                    queries([{
                                        index: 'getAllGamePrivoderBalanceByMember',
                                        method: 'getAllGamePrivoderBalanceByMember',
                                        params: [],
                                        attributes: []
                                    },
                                    {
                                        index: 'member',
                                        method: 'member',
                                        params: [
                                            { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                                        ],
                                        attributes: ['wallet_amount']
                                    },])
                                        .then(({ data }) => {

                                            setLoading({ show: false, message: null });
                                            setLoadingTransfer(false);
                                            appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                                            appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })


                                        }).catch((error) => {
                                            console.log('error: ', error)
                                            setLoading(false)
                                            setLoadingTransfer(false);
                                            if (error?.networkError?.statusCode === 401) {
                                                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                                            } else {
                                                appDispatch({
                                                    type: APP_STORE_ACTION.SHOW_ALERT,
                                                    payload: { description: error.message.toString(), typeAlert: 'error' }
                                                });
                                            }
                                        })

                                }

                            })
                            .catch((error) => {

                                console.log('error: ', error)
                                setLoading(false)
                                setLoadingTransfer(false);
                                if (error?.networkError?.statusCode === 401) {
                                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                                } else {
                                    appDispatch({
                                        type: APP_STORE_ACTION.SHOW_ALERT,
                                        payload: { description: error.message.toString(), typeAlert: 'error' }
                                    });
                                }
                            })
                    }

                }).catch((error) => {
                    if (!isInAppBrowser) {
                        mytab.close()
                    }
                    setLoading({ show: false, message: null });
                    setLoadingTransfer(false);
                    console.log('error: ', error)
                    if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                    } else {
                        appDispatch({
                            type: APP_STORE_ACTION.SHOW_ALERT,
                            payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                    }

                })
            }


        }
        else {
            return appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: t('error_login_continue'), typeAlert: 'error' }
            });
        }

    }


    // GET DEFAULT DATA
    useEffect(() => {

        queries([
            {
                index: 'getGameProviders',
                method: 'getGameProviders',
                params: [
                    { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { '$_game_provider_game_types.status$': "ACTIVE", status: 'ACTIVE' }, order: [['index', 'ASC'], ['name', 'ASC']] } },
                ],
                attributes: [['code', 'game_provider'], ['_game_provider_game_types', ['type']], 'name', 'auto_transfer_after_game_link']
            },
            {
                index: 'getGameTypes',
                method: 'getGameTypes',
                params: [
                    { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', game_provider: `GS`, coming_soon: false }, order: [['updated_at', 'DESC']] } }
                ],
                attributes: [['type', 'code']]
            },
        ]).then(({ data }) => {

            const getAllGameType = data['getGameTypes'].filter(item => item.code !== 'APP');

            // console.log('getAllGameType', getAllGameType)
            gameDispatch({ type: GAME_ACTION.SET_GAME_TYPES, payload: getAllGameType })
            gameDispatch({ type: GAME_ACTION.SET_GAME_PROVIDERS, payload: data['getGameProviders'] })

            // const getFilterData = data['getGameProviders'].filter(item =>
            //     item._game_types.some(type => type.code === data['getGameTypes'][0].code)
            // );

            // gameDispatch({ type: GAME_ACTION.SET_SELECTED_SET_GAME_PROVIDERS, payload: getFilterData })

            // console.log('getGameProvider', data['getGameProviders'])

        }).catch((error) => {
            console.log('error: ', error)
        })




    }, [queries])

    // FILTER BY GAMETYPE
    useEffect(() => {

        const getFilterData = gameState.gameProviders.filter(item => {
            if (gameState.selectedGameType?.code === 'SL') {
                return item._game_provider_game_types.some(type => type.type === 'SL' || type.type === 'APP')
            }
            return item._game_provider_game_types.some(type => type.type === gameState.selectedGameType?.code)
        });



        gameDispatch({ type: GAME_ACTION.SET_SELECTED_SET_GAME_PROVIDERS, payload: getFilterData })


    }, [gameState.selectedGameType, gameState.gameProviders])


    useEffect(() => {
        const userAgent = navigator.userAgent;

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
            setDeviceType("mobile");
        } else {
            setDeviceType("desktop");
        }

        const isInAppBrowser = /FBAV|FBAN|Instagram|Twitter|Line/i.test(userAgent);

        if (isInAppBrowser) {
            setIsInAppBrowser(true)
        } else {
            setIsInAppBrowser(false)
        }

    }, [])

    const fillCount = ((isDesktopOrLaptop ? (rowDesktop * sliderRow) : (rowMobile * sliderRow)) - gameState.selectedGameProviders?.length % (isDesktopOrLaptop ? (rowDesktop * sliderRow) : (rowMobile * sliderRow))) % (isDesktopOrLaptop ? (rowDesktop * sliderRow) : (rowMobile * sliderRow));
// console.log('fillCount',fillCount)

    // console.log('testing',gameState.selectedGameProviders)
    if ((desktopToggle || mobileToggle) === true) {
        return (
            <>
                <section className={`section ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"}`} id="games_01">
                    <div className="main_game">
                        <Container>
                            {titleToggle && <div className="section_header">
                                <div className="font_h2 section_title">
                                    <img src={`${TENANT_BUCKET}/icon/game_title_icon.png`} alt="" />
                                    {t('hot_game_title')}</div>

                            </div>}


                            <div className={`main_game_wrap ${position === 'vertical' ? 'main_game_wrap_vertical' : ''}`}>
                                <Col className={`${position === 'vertical' ? 'vertical_col' : ''} ${menuPosition === 'vertical' ? 'col-lg-2 col-md-3 col-sm-3 col-3' : 'col-lg-2 col-md-4 col-sm-4 col-4'}`}>
                                    <div role="tablist" variant="pills" className={`main_gameSelection ${menuPosition === 'vertical' ? 'main_gameSelection_vertical' : 'main_gameSelection_horizontal'}`}>

                                        {gameState.gameTypes?.filter(item => item.code !== 'APP').map(function (item, index) {
                                            item = GAME_MENU_DATA[item.code] ?? ''
                                            return (
                                                <div key={item.gameType} className={`main_gameSelection_width ${autoMenuWidth?'auto_width':'full_width'}`}>
                                                    <div role="button" tabIndex="0" href="#" onClick={() => onChangeGameType(item.gameType)} className={`main_gameSelection_menu ${item.gameType === gameState.selectedGameType?.code ? 'color_active active' : ''}`}>
                                                        {imageToggle ? <figure className="main_gameSelection_image"><img src={item.gameType === gameState.selectedGameType?.code ? item.selectedIcon : item.icon} alt="" /> </figure> : ""}
                                                        <div>{(t(item.name))}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Col>
                                <Col className={`${position === 'vertical' ? 'vertical_col' : ''} ${menuPosition === 'vertical' ? 'col-lg-10 col-md-9 col-sm-9 col-9' : 'col-lg-10 col-md-8 col-sm-8 col-8'}`}>
                                    <div className={`main_game_container ${sliderToggle && ('slider_main_game_container')}`}>
                                        {sliderToggle ?
                                            <Swiper
                                                slidesPerView={isDesktopOrLaptop ? rowDesktop : rowMobile}
                                                spaceBetween={0}
                                                grid={{
                                                    rows: sliderRow,
                                                    fill: "row",

                                                }}
                                                slidesPerGroup={1}
                                                autoplay={autoplay ? autoplays : false}
                                                id="mainGame_game_01"
                                                pagination={pagination ? paginations : false}
                                                navigation={navigation ?? false}
                                                breakpoints={{

                                                    320: {

                                                        slidesPerView: rowMobile,
                                                        spaceBetween: 10,
                                                    },

                                                    768: {

                                                        slidesPerView: rowMobile,
                                                    },
                                                    1000: {

                                                        slidesPerView: rowDesktop,
                                                    },

                                                }}
                                                modules={[Autoplay, Pagination, Navigation, Grid]}
                                                className="w-100"
                                            >


                                                {gameState.selectedGameProviders?.map(function (item, index) {
                                                    let _item = GAME_MENU_DATA[gameState.selectedGameType?.code] ?? ''
                                                    return (
                                                        <SwiperSlide>
                                                            <Link to={`${(_item?.gameType !== 'SL' && !isDesktopOrLaptop) ? '#' : `${_item.url}#${item.game_provider}`}`} key={`gameProvider_${_item?.gameType}_${item.game_provider}`} onClick={_item?.gameType !== 'SL' ? () => onClickGameProvider(item) : null}>
                                                                <img src={`${TENANT_BUCKET}/game-provider/${folderImage !== '' ? `${folderImage}/` : `${imageSize ?? '1x1'}/`}${_item?.gameType}/${item.game_provider}.png`} alt="" className="navgameimg" width="" />
                                                                {gameProviderNameToggle && (<div className="main_game_name">{item.name}</div>)}
                                                            </Link>
                                                        </SwiperSlide>

                                                    )
                                                })}
                                                {Array.from({ length: fillCount }).map((_, index) => (
                                                    <SwiperSlide key={`filler_${index}`} style={{ width: `${widthColumn}%`, visibility: 'hidden' }}></SwiperSlide>
                                                ))}

                                            </Swiper>

                                            : <> {gameState.selectedGameProviders?.map(function (item, index) {
                                                let _item = GAME_MENU_DATA[gameState.selectedGameType?.code] ?? ''
                                                return (
                                                    <Link to={`${(_item?.gameType !== 'SL' && !isDesktopOrLaptop) ? '#' : `${_item.url}#${item.game_provider}`}`} style={{ width: widthColumn + '%' }} key={`gameProvider_${_item?.gameType}_${item.game_provider}`} onClick={_item?.gameType !== 'SL' ? () => onClickGameProvider(item) : null}>
                                                        <img src={`${TENANT_BUCKET}/game-provider/${folderImage !== '' ? `${folderImage}/` : `${imageSize ?? '1x1'}/`}${_item?.gameType}/${item.game_provider}.png`} alt="" className="navgameimg" width="" />
                                                        {gameProviderNameToggle && (<div className="main_game_name">{item.name}</div>)}
                                                    </Link>
                                                )
                                            })}</>}


                                    </div>
                                </Col>
                            </div>

                        </Container>
                    </div>
                </section>
                <TransferBalanceDialog show={transferDialogShow.show} onHide={() => setTransferDialogShow({ ...transferDialogShow, show: false })} balance={transferDialogShow.balance} gameSelect={transferDialogShow.gameSelect} walletAmount={transferDialogShow.walletAmount} />
                {isLoading.show && <Loading message={isLoading.message} />}
                {isLoadingTransfer && <Loading message={'Transferring funds to the game ...'} />}

            </>
        );
    }

    else {
        return (
            <></>)
    }

};



export default MainGame;