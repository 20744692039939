import React, { useState, useEffect, useReducer,useRef } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation, Grid } from "swiper";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../../common/storeContext'
import { TENANT_BUCKET, GLOBAL_BUCKET, GAME_MENU_DATA, IMAGE_SIZE_DYNAMIC } from '../../common/constants'
import { useMiddletier } from "../../common/middletier";
import TransferBalanceDialog from '../../components/TransferBalanceDialog'
import PromoDetailsDialog from '../../components/PromoDetailsDialog'
import PngGameLauncher from '../../components/PngGameLauncher'
import Alert from '../../components/Alert'
import Loading from '../../components/Loading';
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder'
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./template_02.css";
// Import Swiper styles
// import { Grid, Navigation, Pagination } from "swiper";
// import "swiper/css";

// import "swiper/css/pagination";
import PlayTechGameLauncher from '../../components/PlayTechGameLauncher'


const DYNAMIC_ACTION = {
  SET_DEFAULT_DATA: 'SET_DEFAULT_DATA',
  SET_SHOW_DATA: 'SET_SHOW_DATA',
  SET_FILTER_TYPES: 'SET_FILTER_TYPES',
  SET_SELECTED_FILTER_TYPE: 'SET_SELECTED_FILTER_TYPE',
  SET_ALL_TYPES: 'SET_ALL_TYPES'
}

const initialDynamicData = {
  defaultData: [],
  showData: [],
  allType: [],
  filterTypes: [{ code: 'all', name: 'SHOW ALL' }],
  selectedFilterType: { code: 'all', name: 'SHOW ALL' },
}

const dynamicReducer = (state, action) => {
  switch (action.type) {

    case DYNAMIC_ACTION.SET_DEFAULT_DATA: {
      const defaultData = action.payload
      return { ...state, defaultData };
    }

    case DYNAMIC_ACTION.SET_SHOW_DATA: {
      const showData = action.payload
      return { ...state, showData };
    }

    case DYNAMIC_ACTION.SET_ALL_TYPES: {
      const allType = [...action.payload]
      return { ...state, allType };
    }

    case DYNAMIC_ACTION.SET_FILTER_TYPES: {
      const filterTypes = [...action.payload]
      return { ...state, filterTypes };
    }

    case DYNAMIC_ACTION.SET_SELECTED_FILTER_TYPE: {
      const selectedFilterType = state.filterTypes.find((filterTypes) => filterTypes.code === action.payload)
      return { ...state, selectedFilterType };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const Template_02 = (props) => {
  const appState = useStoreState()
  // const [dynamicData, setDynamicData] = useState();
  const [dynamicState, dynamicDispatch] = useReducer(dynamicReducer, initialDynamicData)
  const { query, queries, mutation } = useMiddletier();
  const appDispatch = useStoreDispatch()
  const [promoDetailsDialogShow, setPromoDetailsDialogShow] = useState(false);
  const [transferDialogShow, setTransferDialogShow] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const navigate = useNavigate();
  const cardContainerProps = props.properties ? props.properties.find((itm) => itm.name === 'Card Container') : false
  const { desktopToggle, mobileToggle, label, id, dataType, titleToggle, titleIconToggle, dynamicQuery, data, filter, noPadding, noLink } = props;
  const checkProps = cardContainerProps ? cardContainerProps : props;
  const { contentPosition, actionPosition, rowDesktop, rowMobile, numberOfRow, navigation, speed, pagination, autoplay, folderImage, loopImage } = checkProps
  const isSafari = () => /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  const imageSize = cardContainerProps.image ? cardContainerProps.image : props.imageSize
  let imageSizeDynamic = ''

  const speeds = speed ? speed * 1000 : 3000;
  const { t, i18n } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })
  const [deviceType, setDeviceType] = useState('');
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);
  const [isLoadingTransfer, setLoadingTransfer] = useState(false);

  const gameWindowRef = useRef(null);
  const playtechGameLauncherRef = useRef();
  const [playtectParams, setPlaytechParams] = useState("");
  const [showPlaytechGameLauncher, setShowPlaytechGameLauncher] = useState(false);
  const [showPngGameLauncher, setShowPngGameLauncher] = useState(false);

  const [iframeShow, setIframeShow] = useState(false);

  const [pngGameSetup, setPngGameSetup] = useState({ gameUrl: "", eventTypes: [{}] });


  const paginations = {
    clickable: true,
  };

  const autoplays = {
    delay: speeds,
    disableOnInteraction: false,
  }


  const onChangePromoDetails = (item) => {
    setCurrentItem(item);
    setPromoDetailsDialogShow(true);
  };

  // GET DATA
  useEffect(() => {
    let filterValue, getDynamicQuery

    if (dataType === 'game provider') {
      // console.log('gameprovider',filter)
      if (dynamicQuery) {
        getDynamicQuery = appState.dataTypes?.gameProviders[dynamicQuery].where
      }

      // filterValue = { where: { '$_game_provider_game_types.type$': `${filter}`, '$_game_provider_game_types.status$': `ACTIVE`, status: 'ACTIVE', ...(getDynamicQuery ? getDynamicQuery : {}) } };

      filterValue = {
        where: { ...(filter !== '' ? { '$_game_provider_game_types.type$': `${filter}` } : {}), '$_game_provider_game_types.status$': `ACTIVE`, status: 'ACTIVE', ...(getDynamicQuery ? getDynamicQuery : {}) },
        order: [['index', 'ASC'], ['name', 'ASC']]
      };

      query({
        method: 'getGameProviders',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: filterValue },
        ],
        attributes: [['code', 'game_provider'], ['_game_provider_game_types', ['type']], 'name', 'live_link', 'auto_transfer_after_game_link']

      }).then(({ data }) => {

        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data['getGameProviders'] })

      }).catch((error) => {
        // console.log('error: ', error)
      })

    }
    else if (dataType === 'game') {

      if (dynamicQuery) {
        getDynamicQuery = appState.dataTypes?.games[dynamicQuery].where
      }

      //  const speeds = numberOfRow !== -1 ? 
      filterValue = { where: { type: `${filter}`, '$_game_provider.status$': `ACTIVE`, status: 'ACTIVE', ...(getDynamicQuery ? getDynamicQuery : {}) }, limit: 10 }
      // const filterValue2 = { where: { type: `${filter}`, status: 'ACTIVE', ...(getDynamicQuery ? getDynamicQuery : {}) } };
      query({
        method: 'getGames',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: filterValue },
        ],
        attributes: ['id', 'name', 'type', 'game_provider', 'code', ['_game_provider', ['status']]]

      }).then(({ data }) => {

        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data['getGames'] })
        // console.log('getGame: ', data['getGames'])
      }).catch((error) => {
        // console.log('error: ', error)
      })

    }
    else if (dataType === 'promotion') {

      queries([{
        index: 'getPromotions',
        method: 'getPromotions',
        params: [
          { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
          { code: 'appearInWebsite', graphqlType: 'Boolean', required: false, value: true },
          { code: 'haveImage', graphqlType: 'Boolean', required: false, value: true },
        ],
        attributes: []
      }, {
        index: 'getPromotionCategories',
        method: 'getPromotionCategories',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE' } } },
        ],
        attributes: ['code', 'name']
      }]).then(({ data }) => {

        const getPromotionsData = data['getPromotions']?.map((item) => {
          return { ...item, category: JSON.parse(item.category) };
        })

        // console.log('getPromotionsData: ', getPromotionsData)
        const getPromoTypes = getPromotionsData.reduce((result, promo) => {
          promo.category.forEach((t) => {
            if (result.indexOf(t) === -1) {
              result.push(t);
            }
          });
          return result
        }, ["ALL"])
        // console.log('getPromoTypes', getPromoTypes)

        // const getPromoTypes = [{ code: 'all', name: 'show_all' }, ...data['getGameTypes'].map(item => item)];

        dynamicDispatch({ type: DYNAMIC_ACTION.SET_ALL_TYPES, payload: data['getPromotionCategories'] })
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_FILTER_TYPES, payload: getPromoTypes })
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_DEFAULT_DATA, payload: data['getPromotions'] })
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data['getPromotions'] })

        // console.log('promo', data['getPromotions'])
      }).catch((error) => {
        // console.log('error: ', error)
      })

    }
    else if (dataType === 'gameTypes') {
      query({
        method: 'getGameTypes',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', game_provider: `GS` }, order: [['updated_at', 'DESC']] } }
        ],
        attributes: [['type', 'code'], 'coming_soon']

      }).then(({ data }) => {
        // Filter out objects with code 'APP' because currently 'APP' is grouped with 'SL'
        const getNewGameType = data['getGameTypes']?.filter(item => item.code !== 'APP');

        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: getNewGameType })
      }).catch((error) => {
        // console.log('error: ', error)
      })
    }
    else {
      dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data })
      // setDynamicData(data)

    }

  }, [query, queries, data, dataType, filter, i18n.resolvedLanguage, appState.dataTypes?.gameProviders, appState.dataTypes?.games, dynamicQuery])


  const onClickGame = (game) => {
    if (appState.loggedIn) {
      setLoading(true);
      // console.log('get Game Type:',game)

      // --------------------------------------------------------------
      let currentGame = {
        getCurrentGameType: '',
        getCurrentGameProvider: '',
        getCurrentGameCode: '',
        getCurrentGameUrl: '',
        getCurrentGameAfterGameLink: false,
      }

      if (dataType === 'game provider') {
        // console.log(game)
        // console.log('game_provider_game_types',game._game_provider_game_types)
        currentGame.getCurrentGameType = game._game_provider_game_types[0].type
        currentGame.getCurrentGameProvider = game.game_provider
        currentGame.getCurrentGameCode = game.code
        currentGame.getCurrentGameAfterGameLink = game.auto_transfer_after_game_link
      }
      else if (dataType === 'game') {
        currentGame.getCurrentGameType = game.type
        currentGame.getCurrentGameProvider = game.game_provider
        currentGame.getCurrentGameCode = game.code
      } else {
        currentGame.getCurrentGameType = game.sub_type
        currentGame.getCurrentGameProvider = game.game_provider
        currentGame.getCurrentGameCode = game.code
      }

      // --------------------------------------------------------
      let mytab

      if (!isInAppBrowser&& currentGame.getCurrentGameProvider !== 'PLAYNGO'&& currentGame.getCurrentGameProvider !== 'PLAYTECH') {
        // OPEN NEW TAB FOR GAME
        mytab = window.open("/loading.html", "_blank");
      }


      // IF GAME PROVIDER AUTO TRANSFER AFTER GAME LINK TRUE (RESTORE FIRST THEN CALL GET GAME LINK)
      if ((dataType === 'game provider' && currentGame.getCurrentGameAfterGameLink)) {

        mutation([{
          method: 'restoreGameWalletByMember',
          params: [
            { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
            { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
            { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
          ],
          attributes: []

        }])
          .then(({ data }) => {
            if (!isInAppBrowser&& currentGame.getCurrentGameProvider !== 'PLAYNGO'&& currentGame.getCurrentGameProvider !== 'PLAYTECH') {
              mytab.location = '/loading_transfer.html'
            } else {
              setLoading(false);
              setLoadingTransfer(true);
            }
            // mytab.location = '/loading_transfer.html'
            query({
              method: 'getGameLinkByMember',
              params: [
                { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
                { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
                { code: 'gameType', graphqlType: 'String', required: true, value: currentGame.getCurrentGameType },
                { code: 'game', graphqlType: 'String', required: false, value: currentGame.getCurrentGameCode },
                { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
                { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
              ],
              attributes: []

            }).then(({ data }) => {

              const gameBalance = data['getGameLinkByMember'].balance
              const gameUrl = data['getGameLinkByMember'].gameLink
              const gameWalletAmount = data['getGameLinkByMember'].wallet_amount

              currentGame.getCurrentGameUrl = gameUrl

              if (gameUrl === "") {
                setLoading(false);

                if (!isInAppBrowser&& currentGame.getCurrentGameProvider !== 'PLAYNGO'&& currentGame.getCurrentGameProvider !== 'PLAYTECH') {
                  mytab.close()
                } else {
                  setLoadingTransfer(false);
                }
                // mytab.close()
                const getResponse = JSON.parse(data['getGameLinkByMember'].response)
                const getResponseErrorMsg = getResponse?.errMsg

                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: getResponseErrorMsg, typeAlert: 'error' }
                });

                return
              } else {

                if (!isInAppBrowser&& currentGame.getCurrentGameProvider !== 'PLAYNGO'&& currentGame.getCurrentGameProvider !== 'PLAYTECH') {
                  mytab.location = gameUrl
                } else {
                  setLoadingTransfer(false);
                }
                // mytab.location = gameUrl

                if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                  setLoading(false);
                  setLoadingTransfer(false);
                  setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: { ...currentGame }, walletAmount: gameWalletAmount });
                } else {
                  if (isInAppBrowser) {
                    window.location.href = gameUrl;
                  }
                  queries([{
                    index: 'getAllGamePrivoderBalanceByMember',
                    method: 'getAllGamePrivoderBalanceByMember',
                    params: [],
                    attributes: []
                  },
                  {
                    index: 'member',
                    method: 'member',
                    params: [
                      { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                    ],
                    attributes: ['wallet_amount']
                  },])
                    .then(({ data }) => {

                      setLoading(false);
                      setLoadingTransfer(false);
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })


                    }).catch((error) => {
                      // console.log('error: ', error)
                      setLoading(false)
                      setLoadingTransfer(false);
                      if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                      } else {
                        appDispatch({
                          type: APP_STORE_ACTION.SHOW_ALERT,
                          payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                      }
                    })
                }




              }

            }).catch((error) => {
              // mytab.close()
              if (!isInAppBrowser&& currentGame.getCurrentGameProvider !== 'PLAYNGO'&& currentGame.getCurrentGameProvider !== 'PLAYTECH') {
                mytab.close()
              }
              setLoading(false);
              // console.log('error: ', error)
              if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
              } else {
                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: error.message.toString(), typeAlert: 'error' }
                });
              }
            })

          })
          .catch((error) => {
            // console.log('error: ', error)
            // mytab.close()
            if (!isInAppBrowser&& currentGame.getCurrentGameProvider !== 'PLAYNGO'&& currentGame.getCurrentGameProvider !== 'PLAYTECH') {
              mytab.close()
            }
            setLoading(false)
            if (error?.networkError?.statusCode === 401) {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })




      } else {
        let method = 'getGameLinkByMember';
        let params = [
          { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
          { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
          { code: 'gameType', graphqlType: 'String', required: true, value: currentGame.getCurrentGameType },
          { code: 'game', graphqlType: 'String', required: false, value: currentGame.getCurrentGameCode },
          { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
          { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
        ];

        query({
          method,
          params,
          attributes: []

        }).then(({ data }) => {
          // setLoading(false)
          // console.log('getGameLinkByMember', data[method])

          const gameBalance = data[method].balance
          const gameUrl = data[method].gameLink
          const gameWalletAmount = data[method].wallet_amount
          currentGame.getCurrentGameUrl = gameUrl

          if (gameUrl === "") {
            setLoading(false);
            mytab?.close()
            const getResponse = JSON.parse(data[method].response)
            const getResponseErrorMsg = getResponse?.errMsg

            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: getResponseErrorMsg, typeAlert: 'error' }
            });

            return
          } else {

            if (!isInAppBrowser&& currentGame.getCurrentGameProvider !== 'PLAYNGO'&& currentGame.getCurrentGameProvider !== 'PLAYTECH') {
              mytab.location = '/loading_transfer.html'
            } else {
              setLoading(false);
              setLoadingTransfer(true);
            }

            mutation([{
              method: 'restoreGameWalletByMember',
              params: [
                { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
                { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
                { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
              ],
              attributes: []

            }])
              .then(({ data }) => {
                // console.log('data[restoreGameWalletByMember]', data['restoreGameWalletByMember'])

               if (!isInAppBrowser&& currentGame.getCurrentGameProvider !== 'PLAYNGO'&& currentGame.getCurrentGameProvider !== 'PLAYTECH') {
                  mytab.location = gameUrl
                } else {
                  setLoadingTransfer(false);

                }

                if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                  setLoading(false);
                  setLoadingTransfer(false);
                  setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: { ...currentGame }, walletAmount: gameWalletAmount });
                }

                else {
                  if (isInAppBrowser&& currentGame.getCurrentGameProvider !== 'PLAYNGO'&& currentGame.getCurrentGameProvider !== 'PLAYTECH') {
                    window.location.href = gameUrl;
                  }

                  if (currentGame.getCurrentGameProvider === 'PLAYTECH') {
                    setPlaytechParams(JSON.parse(gameUrl));
                    setIframeShow(true)
                    setShowPngGameLauncher(false);
                    setShowPlaytechGameLauncher(true);

                    window.history.pushState({ iframe: true }, "iframe");

                    setTimeout(() => {
                      if (playtechGameLauncherRef.current && typeof playtechGameLauncherRef.current.login === "function") {
                        playtechGameLauncherRef.current.login();
                      } else {
                        console.error("PlaytechGameLauncher is not ready.");
                      }
                    }, 0);
                  }

                  if (currentGame.getCurrentGameProvider === 'PLAYNGO') {
                    console.log('click png')
                    setIframeShow(true)
                    setShowPngGameLauncher(true);
                    setShowPlaytechGameLauncher(false);
                    window.history.pushState({ iframe: true }, "iframe");
                    setPngGameSetup({
                      gameUrl: gameUrl,
                      eventTypes: [
                        { eventType: "gameReady", call: () => console.log("Game is ready") },
                        { eventType: "reloadGame", call: () => window.location.reload() },
                        { eventType: "backToLobby", call: () => window.location.reload() },
                      ]
                    });
                  }

                  queries([{
                    index: 'getAllGamePrivoderBalanceByMember',
                    method: 'getAllGamePrivoderBalanceByMember',
                    params: [],
                    attributes: []
                  },
                  {
                    index: 'member',
                    method: 'member',
                    params: [
                      { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                    ],
                    attributes: ['wallet_amount']
                  },])
                    .then(({ data }) => {

                      setLoading(false);
                      setLoadingTransfer(false);
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })


                    }).catch((error) => {
                      // console.log('error: ', error)
                      setLoading(false)
                      setLoadingTransfer(false);
                      if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                      } else {
                        appDispatch({
                          type: APP_STORE_ACTION.SHOW_ALERT,
                          payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                      }
                    })

                }

              })
              .catch((error) => {
                // console.log('error: ', error)
                setLoading(false)
                if (error?.networkError?.statusCode === 401) {
                  appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                  appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: error.message.toString(), typeAlert: 'error' }
                  });
                }
              })
          }



        }).catch((error) => {
          // mytab.close()
          if (!isInAppBrowser&& currentGame.getCurrentGameProvider !== 'PLAYNGO'&& currentGame.getCurrentGameProvider !== 'PLAYTECH') {
            mytab.close()
          }
          setLoading(false);
          // console.log('error: ', error)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
      }



    }
    else {
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: t('error_login_continue'), typeAlert: 'error' }
      });
    }

  }

  const determineLinkTo = (_item, item) => {
    if (dataType === 'gameTypes') {
      return _item.url;
    }
    if (dataType === 'game provider' && filter === 'SL') {
      return `slots#${item.game_provider}`;
    }

    if (dataType === 'games' || dataType === 'game') {
      return `#${item.game_provider}`;
    }
  };

  const handleLinkClick = (item) => {
    if ((dataType === 'game' || dataType === 'games' || (dataType === 'game provider' && filter !== 'SL')) && !noLink) {
      onClickGame(item);
    } else {

    }
  };

  const getImageSize = (device, size, content, items) => {

    const value = IMAGE_SIZE_DYNAMIC[size] &&
      IMAGE_SIZE_DYNAMIC[size][device] &&
      IMAGE_SIZE_DYNAMIC[size][device][content] &&
      IMAGE_SIZE_DYNAMIC[size][device][content][items];
    return value || null;
  }

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      setDeviceType("mobile");
    } else {
      setDeviceType("desktop");
    }

    const isInAppBrowser = /FBAV|FBAN|Instagram|Twitter|Line/i.test(userAgent);

    if (isInAppBrowser) {
      setIsInAppBrowser(true)
    } else {
      setIsInAppBrowser(false)
    }

  }, [])

  const closeIframe = () => {
    setIframeShow(false);
    if(showPlaytechGameLauncher){
      window.location.reload()
    }
    if (window.history.state?.iframe) {
      window.history.back();
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state?.iframe) {
        setIframeShow(false);
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  if (dataType === 'game' || dataType === 'games') {
    imageSizeDynamic = getImageSize(isDesktopOrLaptop ? 'desktop' : 'mobile', imageSize, contentPosition || 'bottom', isDesktopOrLaptop ? rowDesktop : rowMobile);
  }

  if ((desktopToggle || mobileToggle) === true) {
    return (
      <>
        <section className={`section ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"} dynamic_template_2 `} id={`layout_02`}>
          <div id={`${id}`}>
            <div className={`${id}_body layout_body ${noPadding && 'pb-0'}`}>
              <Container>
                <div className={`${id}_wrap`}>
                  {titleToggle ?
                    <div className={`section_header`}>
                      <div className="font_h2 section_title">
                        {titleIconToggle && (<img src={`${TENANT_BUCKET}/icon/${id}_title_icon.png`} alt="title icon" />)}
                        {label && t(label)}</div>
                      {filter !== '' && (<Link to={`../${dataType === 'game' ? 'slots' : (dataType === 'game provider' ? `${GAME_MENU_DATA[filter].url}` : (dataType === 'promotion' ? 'promotions' : ''))}`} className="section_details">
                        {t('view_more')} &nbsp;
                        <AiOutlineRight />
                      </Link>)}
                    </div>
                    : <></>}

                  <div className="templateSliderContainer">
                    <Swiper
                      slidesPerView={isDesktopOrLaptop ? rowDesktop : rowMobile}
                      spaceBetween={0}
                      grid={{
                        rows: !numberOfRow ? 1 : (numberOfRow !== '-1' ? numberOfRow : 1),
                        fill: "row",

                      }}
                      slidesPerGroup={isDesktopOrLaptop ? 1 : 1}
                      autoplay={autoplay ? autoplays : false}
                      loop={loopImage !== undefined ? loopImage : true}
                      pagination={pagination ? paginations : false}
                      navigation={navigation ?? false}
                      breakpoints={{

                        320: {
                          // width: 320,
                          slidesPerView: rowMobile,
                          spaceBetween: 10,
                        },
                        // when window width is >= 640px
                        // 640: {
                        //   width: 640,
                        //   slidesPerView: 2,
                        // },
                        // when window width is >= 768px
                        768: {
                          // width: 768,
                          slidesPerView: rowMobile,
                        },
                        1000: {
                          // width: 1000,
                          slidesPerView: rowDesktop,
                        },

                      }}
                      modules={[Autoplay, Pagination, Navigation, Grid]}
                      className="w-100"
                    >

                      <div className="row">
                        {Object.values(dynamicState.showData ?? "").map((item, index) => {
                          let _item = GAME_MENU_DATA[item.code] ?? ''
                          return (
                            <SwiperSlide key={`${dataType}_${filter}_${item.id}_${index}`}>
                              <div className="card_container">
                                <Link to={determineLinkTo(_item, item)} className={`card_wrap card_image_${imageSize} card_position_${contentPosition} card_action_${actionPosition} card_${noLink ? 'noLink' : 'link'}`} onClick={() => handleLinkClick(item)}>
                                  {/* <Link to={`${dataType === 'gameTypes' ? _item.url : ((dataType === 'game provider' && filter === 'SL') ? `slots#${item.game_provider}` : '#')}`}> */}
                                  {/* --- IMAGE PART START --- */}
                                  <figure className={`card_image_wrap ${item?.coming_soon ? 'coming_soon_image' : ''}`}>
                                    {(dataType === 'game' || dataType === 'games') ?
                                      <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${item.game_provider}/${imageSize}/img_jpg/${item.code}_${imageSize}_w${imageSizeDynamic}.jpg`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                      (dataType === 'game provider' ?
                                        <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-provider/${folderImage !== '' ? `${folderImage}/` : `${imageSize ?? '1x1'}/`}${folderImage !== 'logo' ? `${filter}/` : ''}${item.game_provider}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                        (dataType === 'gameTypes' ?
                                          <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-type/${imageSize}/${item.code}${item?.coming_soon ? '_coming_soon_' : '_'}${i18n.resolvedLanguage}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                          ((dataType === 'promotion' || dataType === 'promotions') ?
                                            <ImageWithPlaceholder src={`${isDesktopOrLaptop ? (item._desktop_image?.url) : (item._mobile_image?.url)}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                            <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${item.game_provider}/${imageSize}/${item.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} />)
                                        ))
                                    }
                                    {/* IMAGE PART CAPTION */}
                                    <figcaption></figcaption>

                                    {/* --- ACTIVE HOVER (INSIDE IMAGE PART) START --- */}
                                    {actionPosition === 'hover' && <div className="card_action_wrap">
                                      {
                                        (dataType === 'game' || dataType === 'games' || dataType === 'game provider') ?
                                          <button className="color_button font_button button_action button_action1" >{item.action_1 ?? t('play_now')}</button> :
                                          ((dataType === 'promotion' || dataType === 'promotions') ?
                                            <>
                                              <button className="color_button font_button button_action button_action1" onClick={() => onChangePromoDetails(item)} >{item.action_1 ?? t('more_info')}</button>
                                              {!appState.loggedIn ?
                                                <button className="color_button font_button button_action button_action2">{item.action_2 ?? t('join_now')}</button> :
                                                <button className="color_button font_button button_action button_action2">{item.action_2 ?? t('apply_now')}</button>}
                                            </> :
                                            <></>
                                          )

                                      }


                                    </div>}
                                    {/* --- ACTIVE HOVER (INSIDE IMAGE PART) END --- */}

                                  </figure>
                                  {/* --- IMAGE PART END --- */}


                                  {contentPosition !== 'none' && <div className="color_primary content_container">
                                    <article className="card_content_wrap">
                                      <div className="card_content_title">{item.title ?? item?.name}</div>
                                      {/* <p className="card_content_desc">{item.description}</p> */}
                                    </article>

                                    {/* --- ACTIVE BOTTOM START --- */}
                                    {actionPosition === 'bottom' && <div className="card_action_wrap">
                                      {
                                        (dataType === 'game' || dataType === 'games') ?
                                          <button className="color_button font_button button_action button_action1">{item.action_1 ?? t('play_now')}</button> :
                                          ((dataType === 'promotion' || dataType === 'promotions') ?
                                            <>
                                              <button className="color_button font_button button_action button_action1" onClick={() => onChangePromoDetails(item)} >{item.action_1 ?? t('more_info')}</button>
                                              {!appState.loggedIn ?
                                                <Link to="/register" className="color_button font_button button_action button_action2">{item.action_2 ?? t('join_now')}</Link> :
                                                <Link to="/wallet/transfer" className="color_button font_button button_action button_action2">{item.action_2 ?? t('apply_now')}</Link>}
                                            </> :
                                            <></>
                                          )

                                      }
                                    </div>}
                                    {/* --- ACTIVE BOTTOM END --- */}
                                  </div>}
                                </Link>
                                {/* </section> */}
                                {currentItem && (
                                  <PromoDetailsDialog
                                    show={promoDetailsDialogShow}
                                    onHide={() => setPromoDetailsDialogShow(false)}
                                    getdata={currentItem}
                                  />
                                )}
                              </div>
                            </SwiperSlide>
                          );
                        })}

                      </div>

                      {/* {imageSize === '1x1' ?
                <>

                  {contentPosition === 'bottom' ?
                    <>
                      <div className="row">
                        {dynamicData.map((data, key) => {
                          return (
                            <SwiperSlide key={key}>
                              <Card>
                              <Card.Img variant="top" src={`${TENANT_BUCKET}/demo-image/1x1.png`} className="img_rounded" />
                                <Card.Body className="color_primary">
                                  <Card.Title>Card Title {key}</Card.Title>
                                  <Card.Text>
                                    Some quick example text to build on the card title and make up
                                    the bulk of the card's content.
                                  </Card.Text>
                                  <Card.Link href="#">Card Link</Card.Link>
                                  <Card.Link href="#">Another Link</Card.Link>
                                </Card.Body>
                              </Card>
                            </SwiperSlide>
                          );
                        })}
                      </div>
                    </> :
                    (contentPosition === 'right' ?
                      <>
                        <div className="row">
                          {dynamicData.map((data, key) => {
                            return (
                              <SwiperSlide key={key}>

                                <Card className="d-flex flex-row me-md-2">
                                <Card.Img variant="top" src={`${TENANT_BUCKET}/demo-image/1x1.png`} className="img_rounded" />
                                  <Card.Body className="color_primary">
                                    <Card.Title>Card title</Card.Title>
                                    <Card.Text>
                                      This card has supporting text below as a natural lead-in to
                                      additional content.
                                    </Card.Text>
                                  </Card.Body>
                                </Card>

                              </SwiperSlide>
                            );
                          })}
                        </div>

                      </> :
                      <>
                        <div className="row">
                          {dynamicData.map((data, key) => {
                            return (
                              <SwiperSlide key={key}>
                                <Card>
                                <Card.Img variant="top" src={`${TENANT_BUCKET}/demo-image/1x1.png`} className="img_rounded" />
                                </Card>
                              </SwiperSlide>
                            );
                          })}
                        </div>

                      </>)}

                </> :
                (imageSize === '1x2' ?
                  <>

                    {contentPosition === 'bottom' ?
                      <>
                        <div className="row">
                          {dynamicData.map((data, key) => {
                            return (
                              <SwiperSlide>
                                <Card key={key}>
                                <Card.Img variant="top" src={`${TENANT_BUCKET}/demo-image/1x2.png`} className="img_rounded" />
                                  <Card.Body className="color_primary">
                                    <Card.Title>Card Title</Card.Title>
                                    <Card.Text>
                                      Some quick example text to build on the card title and make up
                                      the bulk of the card's content.
                                    </Card.Text>
                                    <Card.Link href="#">Card Link</Card.Link>
                                    <Card.Link href="#">Another Link</Card.Link>
                                  </Card.Body>
                                </Card>
                              </SwiperSlide>
                            );
                          })}
                        </div>
                      </> :
                      (contentPosition === 'right' ?
                        <>
                          <div className="row">
                            {dynamicData.map((data, key) => {
                              return (
                                <SwiperSlide key={key}>
                                  <Card className="d-flex flex-row me-md-2">
                                  <Card.Img variant="top" src={`${TENANT_BUCKET}/demo-image/1x2.png`} className="img_rounded" />
                                    <Card.Body className="color_primary">
                                      <Card.Title>Card title</Card.Title>
                                      <Card.Text>
                                        This card has supporting text below as a natural lead-in to
                                        additional content.
                                      </Card.Text>
                                    </Card.Body>
                                  </Card>
                                </SwiperSlide>
                              );
                            })}
                          </div>
                        </> :
                        <>
                          <div className="row">
                            {dynamicData.map((data, key) => {
                              return (
                                <SwiperSlide key={key}>
                                  <Card>
                                  <Card.Img variant="top" src={`${TENANT_BUCKET}/demo-image/1x2.png`} className="img_rounded" />
                                  </Card>
                                </SwiperSlide>
                              );
                            })}
                          </div>
                        </>)}

                  </> :
                  (imageSize === '1x3' ?
                    <>

                      {contentPosition === 'bottom' ?
                        <>
                          <div className="row">
                            {dynamicData.map((data, key) => {
                              return (
                                <SwiperSlide key={key}>
                                  <Card>
                                    <Card.Img variant="top" src={`${TENANT_BUCKET}/demo-image/1x3.jpg`} className="img_rounded" />
                                    <Card.Body className="color_primary">
                                      <Card.Title>Card Title</Card.Title>
                                      <Card.Text>
                                        Some quick example text to build on the card title and make up
                                        the bulk of the card's content.
                                      </Card.Text>
                                      <Card.Link href="#">Card Link</Card.Link>
                                      <Card.Link href="#">Another Link</Card.Link>
                                    </Card.Body>
                                  </Card>
                                </SwiperSlide>
                              );
                            })}
                          </div>
                        </> :
                        (contentPosition === 'right' ?
                          <>
                            <div className="row">
                              {dynamicData.map((data, key) => {
                                return (
                                  <SwiperSlide key={key}>
                                    <Card className="d-flex flex-row me-md-2">
                                      <Card.Img variant="top" src={`${TENANT_BUCKET}/demo-image/1x3.jpg`} />
                                      <Card.Body className="color_primary">
                                        <Card.Title>Card title</Card.Title>
                                        <Card.Text>
                                          This card has supporting text below as a natural lead-in to
                                          additional content.
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  </SwiperSlide>
                                );
                              })}
                            </div>
                          </> :
                          <>
                            <div className="row">
                              {dynamicData.map((data, key) => {
                                return (
                                  <SwiperSlide key={key}>
                                    <Card>
                                      <Card.Img variant="top" src={`${TENANT_BUCKET}/demo-image/1x3.jpg`} className="img_rounded" />
                                    </Card>
                                  </SwiperSlide>
                                );
                              })}
                            </div>
                          </>)}

                    </> :
                    (imageSize === '2x1' ?
                      <>

                        {contentPosition === 'bottom' ?
                          <>
                          </> :
                          (contentPosition === 'right' ?
                            <>
                            </> :
                            <>
                              <div className="row">
                                {dynamicData.map((data, key) => {
                                  return (
                                    <SwiperSlide>
                                      <Card>
                                        <Card.Img variant="top" src={`${TENANT_BUCKET}/demo-image/2x1.jpg`} className="img_rounded" />
                                      </Card>
                                    </SwiperSlide>
                                  );
                                })}
                              </div>
                            </>)}

                      </> :
                      <></>)))} */}
                    </Swiper>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </section>
        <TransferBalanceDialog show={transferDialogShow.show} onHide={() => setTransferDialogShow({ ...transferDialogShow, show: false })} balance={transferDialogShow.balance} gameProvider={transferDialogShow.gameProvider} gameUrl={transferDialogShow.gameUrl} />

        {isLoading && <Loading />}
        {isLoadingTransfer && <Loading message={'Transferring funds to the game ...'}/>}
        {(iframeShow) && (<div className="iframeContainer">
          <div className="closeIframe" onClick={closeIframe}><button>X Close</button></div>
          {showPngGameLauncher && (<PngGameLauncher gameUrl={pngGameSetup?.gameUrl} eventTypes={pngGameSetup?.eventTypes} />)}
          {showPlaytechGameLauncher && (<PlayTechGameLauncher ref={playtechGameLauncherRef} {...playtectParams} />)}
        </div>)}
      </>
    );
  };
}


export default Template_02;
