import React, { useEffect, useRef, useReducer, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { AiFillExclamationCircle, AiOutlineCopy } from "react-icons/ai";
import { Container, Nav, Form, Modal } from "react-bootstrap";
import { BiSearch, BiArrowBack } from 'react-icons/bi'
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
// import PageTitle from '../pageTitle/template_01';
import SlotGameContainer from '../../dynamic_section/dynamic_1/template_01';
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../../common/storeContext'
import { SLOT_GAME_TYPE, GLOBAL_BUCKET, TENANT_BUCKET } from '../../common/constants'
import TransferBalanceDialog from '../../components/TransferBalanceDialog'
import { Link } from 'react-router-dom'
import { useMiddletier } from "../../common/middletier";
import Loading from '../../components/Loading';
import "./template_01.css";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation]);

const SLOT_ACTION = {
  SET_GAME_PROVIDERS: 'SET_GAME_PROVIDERS',
  SET_SELECTED_GAME_PROVIDER: 'SET_SELECTED_GAME_PROVIDERS',
  SET_ALL_SUB_GAME_TYPES: 'SET_ALL_SUB_GAME_TYPES',
  SET_GAME_TYPES: 'SET_GAME_TYPES',
  SET_SELECTED_GAME_TYPE: 'SET_SELECTED_GAME_TYPE',
  SET_SORT_GAMES: 'SET_SORT_GAMES',
  SET_SELECTED_SORT_GAMES: 'SET_SELECTED_SORT_GAMES',
  SET_ALL_GAME: 'SET_ALL_GAME',
  SET_GAMES: 'SET_GAMES',
  SET_FILTER_GAMES: 'SET_FILTER_GAMES',
  SET_UNSORTED_GAMES: 'SET_UNSORTED_GAMES',
  SET_SEARCH_INPUT: 'SET_SEARCH_INPUT'
}

const MSLOT_ACTION = {
  SET_IS_APP: 'SET_IS_APP',
  SET_APP_TERMS: 'SET_APP_TERMS',
  SET_GAME_USER_DETAIL: 'SET_GAME_USER_DETAIL',

}

const initialSlotData = {
  gameProviders: [],
  selectedGameProvider: [],
  allSubGameTypes: [],
  gameTypes: [{ code: 'all', name: 'SHOW ALL' }, { code: 'hot', name: 'HOT_GAMES' }, { code: 'new', name: 'NEW GAMES' }],
  selectedGameType: { code: 'all', name: 'SHOW ALL' },
  sortGames: [{ code: 'popularity', name: 'POPULARITY' }, { code: 'a_z', name: 'A_Z' }, { code: 'z_a', name: 'Z_A' }],
  selectedSortGames: { code: 'popularity', name: 'POPULARITY' },
  allGames: [],
  games: [],
  filterGames: [],
  unsortedGames: [],
  searchInput: ''
}

const initialMslotData = {
  isApp: false,
  appTerms: '',
  gameUserDetail: { id: '', game_provider: '', username: '-', password: '-' },
  link: ['android_download_link', 'ios_download_link', 'window_download_link', 'gameLink']
}

const slotReducer = (state, action) => {
  switch (action.type) {

    case SLOT_ACTION.SET_GAME_PROVIDERS: {
      const gameProviders = [...action.payload]
      return { ...state, gameProviders };
    }

    case SLOT_ACTION.SET_SELECTED_GAME_PROVIDER: {
      const selectedGameProvider = state.gameProviders.find((gameProviders) => gameProviders.code === action.payload)
      return { ...state, selectedGameProvider };
    }

    case SLOT_ACTION.SET_ALL_SUB_GAME_TYPES: {
      const allSubGameTypes = [...action.payload]
      return { ...state, allSubGameTypes };
    }

    case SLOT_ACTION.SET_GAME_TYPES: {
      const gameTypes = [...action.payload]
      return { ...state, gameTypes, selectedGameType: gameTypes.length > 0 ? gameTypes[0] : 'ALL' };
    }

    case SLOT_ACTION.SET_SELECTED_GAME_TYPE: {
      // const selectedGameType = state.gameTypes.find((gameTypes) => gameTypes.code === action.payload)
      return { ...state, selectedGameType: action.payload };
    }

    case SLOT_ACTION.SET_SORT_GAMES: {
      const sortGames = [...action.payload]
      return { ...state, sortGames, selectedSortGames: sortGames.length > 0 ? sortGames[0] : 'ALL' };
    }

    case SLOT_ACTION.SET_SELECTED_SORT_GAMES: {
      const selectedSortGames = state.sortGames.find((sortGames) => sortGames.code === action.payload)
      return { ...state, selectedSortGames };
    }

    case SLOT_ACTION.SET_ALL_GAME: {
      const allGames = [...action.payload]
      return { ...state, allGames };
    }

    case SLOT_ACTION.SET_GAMES: {
      const games = [...action.payload]
      return { ...state, games };
    }


    case SLOT_ACTION.SET_FILTER_GAMES: {
      return { ...state, filterGames: action.payload };
    }

    case SLOT_ACTION.SET_UNSORTED_GAMES: {
      return { ...state, unsortedGames: action.payload };
    }

    case SLOT_ACTION.SET_SEARCH_INPUT: {
      return { ...state, searchInput: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}


const mslotReducer = (state, action) => {
  switch (action.type) {

    case MSLOT_ACTION.SET_IS_APP: {
      return { ...state, isApp: action.payload };
    }

    case MSLOT_ACTION.SET_APP_TERMS: {
      return { ...state, appTerms: action.payload };
    }

    case MSLOT_ACTION.SET_GAME_USER_DETAIL: {
      return { ...state, gameUserDetail: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const Slot = (props) => {
  const { imageSize, rowDesktop, rowMobile, gameProviderNameToggle, contentPosition, appFolderImage, gameProviderFolderImage } = props
  const appState = useStoreState()
  const location = useLocation()
  const { queries, mutation } = useMiddletier();
  const { t, i18n } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })
  const appDispatch = useStoreDispatch()
  const [showAppTerm, setShowAppTerm] = useState(false)
  const [gameProviderDetails, setGameProviderDetails] = useState();
  const [slotState, slotDispatch] = useReducer(slotReducer, initialSlotData)
  const [mslotState, mslotDispatch] = useReducer(mslotReducer, initialMslotData)
  const [transferDialogShow, setTransferDialogShow] = useState({});
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [deviceType, setDeviceType] = useState('');
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);
  const [isLoadingTransfer, setLoadingTransfer] = useState(false);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const onChangeGameType = (newValue) => {
    slotDispatch({ type: SLOT_ACTION.SET_SELECTED_GAME_TYPE, payload: newValue })
  }

  const onChangeSearchInput = (newValue) => {
    slotDispatch({ type: SLOT_ACTION.SET_SEARCH_INPUT, payload: newValue })
  }

  const onChangeSortGames = (newValue) => {
    slotDispatch({ type: SLOT_ACTION.SET_SELECTED_SORT_GAMES, payload: newValue })
  }

  const copyText = (newValue) => {
    navigator.clipboard.writeText(newValue);
    appDispatch({
      type: APP_STORE_ACTION.SHOW_ALERT,
      payload: { description: `Copied ${newValue ?? '-'}`, typeAlert: 'success' }
    });
  };

  useEffect(() => {
    if (isDesktopOrLaptop) {
      window.scrollTo(0, 400)

    }
  }, [location, isDesktopOrLaptop])

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      setDeviceType("mobile");
    } else {
      setDeviceType("desktop");
    }

    // alert('userAgent' + userAgent)

    const isInAppBrowser = /FBAV|FBAN|Instagram|Twitter|Line/i.test(userAgent);

    if (isInAppBrowser) {
      setIsInAppBrowser(true)
    } else {
      setIsInAppBrowser(false)
    }

  }, [])


  const onChangeGameProvider = (newValue) => {
    navigate(`/slots#${newValue}`);
  }

  // GET DATA
  useEffect(() => {
    setLoading(true)
    // const where = {...where, '$_game_types.code$': 'SL'}
    queries([{
      index: 'getGameProviders',
      method: 'getGameProviders',
      params: [
        { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { '$_game_provider_game_types.type$': ["SL", "APP"], '$_game_provider_game_types.status$': 'ACTIVE', status: 'ACTIVE' }, order: [['index', 'ASC'], ['name', 'ASC']] } },
      ],
      attributes: ['code', ['_game_provider_game_types', ['type']], 'name', 'live_link', 'status', 'lobby_only', 'is_application', 'auto_transfer_after_game_link', 'hot']
    }, {
      index: 'getSubGameType',
      method: 'getSubGameType',
      params: [
        { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE' } } },
      ],
      attributes: ['code', 'name']
    },
    {
      index: 'getGames',
      method: 'getGames',
      params: [
        { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', '$_game_provider.status$': `ACTIVE` } } },
      ],
      attributes: ['id', 'name', 'sub_type', 'code', 'game_provider', ['_game_provider', ['status']], 'hot', 'ranking', 'favourite', 'created_at']
    },]).then(({ data }) => {
      setLoading(false)
      slotDispatch({ type: SLOT_ACTION.SET_GAME_PROVIDERS, payload: data['getGameProviders'] })
      slotDispatch({ type: SLOT_ACTION.SET_ALL_GAME, payload: data['getGames'] })
      console.log('getGame', data['getGames'])
      slotDispatch({ type: SLOT_ACTION.SET_ALL_SUB_GAME_TYPES, payload: data['getSubGameType'] })

    }).catch((error) => {
      setLoading(false)
      console.log('error: ', error)
    })

  }, [queries, i18n.resolvedLanguage])

  // When Location or app state change 
  useEffect(() => {
    const getLocation = location.hash ? location.hash?.substring(1) : (slotState.gameProviders?.length > 0 && (`${slotState.gameProviders[0].code}`))

    slotDispatch({ type: SLOT_ACTION.SET_SELECTED_GAME_PROVIDER, payload: getLocation })


    const gameByGameProvider = Object.values(slotState.allGames ?? "").filter(e => {
      return e.game_provider === getLocation;
    });

    const gameByGameType = gameByGameProvider.reduce((result, game) => {

      if (result.indexOf(game.sub_type) === -1) {
        result.push(game.sub_type)
      }
      return result
    }, ["ALL", "HOT", "NEW"])
    // console.log('gameByGameType', gameByGameType)
    slotDispatch({ type: SLOT_ACTION.SET_GAME_TYPES, payload: gameByGameType })

    const checkAppType = slotState.gameProviders.find((gameProvider) => gameProvider.code === getLocation) || [];
    const checkAppResult = checkAppType ? (checkAppType._game_provider_game_types?.some(({ type }) => type === 'APP')) : false;
    mslotDispatch({ type: MSLOT_ACTION.SET_IS_APP, payload: checkAppResult })
    slotDispatch({ type: SLOT_ACTION.SET_SEARCH_INPUT, payload: '' })

    setGameProviderDetails({})
  }, [location, slotState.allGames, slotState.gameProviders, appState.user?.username])

  const onClickPlayNowLobby = () => {
    let getGameLinkByMemberParams = []
    let gameSelectParams = []

    if (appState.user?.username) {
      setLoading(true)
      getGameLinkByMemberParams = [
        // { code: 'game', graphqlType: 'String', required: false, value: '0' },
        { code: 'gameProvider', graphqlType: 'String', required: true, value: slotState.selectedGameProvider?.code },
        { code: 'gameType', graphqlType: 'String', required: true, value: 'SL' },
        { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
        { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
        { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
      ]

      gameSelectParams = {
        getCurrentGameProvider: slotState.selectedGameProvider?.code, getCurrentGameType: 'SL'
      }
      // --------------------------------------------------------
      let mytab

      if (!isInAppBrowser) {
        // OPEN NEW TAB FOR GAME
        mytab = window.open("/loading.html", "_blank");
      }

      queries([{
        method: 'getGameLinkByMember',
        params: getGameLinkByMemberParams,
        attributes: []

      }]).then(({ data }) => {
        setGameProviderDetails(data['getGameLinkByMember'])
        const gameBalance = data['getGameLinkByMember']?.balance
        const gameWalletAmount = data['getGameLinkByMember'].wallet_amount
        const gameUrl = data['getGameLinkByMember'].gameLink

        if (gameUrl === "") {
          setLoading(false);
          if (!isInAppBrowser) {
            mytab.close()
          }
          // mytab.close()
          const getResponse = JSON.parse(data['getGameLinkByMember'].response)
          const getResponseErrorMsg = getResponse?.errMsg

          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: getResponseErrorMsg, typeAlert: 'error' }
          });

          return
        } else {
          // mytab.location = '/loading_transfer.html'

          if (!isInAppBrowser) {
            mytab.location = '/loading_transfer.html'
          } else {
            setLoading(false);
            setLoadingTransfer(true);
          }

          mutation([{
            method: 'restoreGameWalletByMember',
            params: [
              { code: 'member', graphqlType: 'String', required: true, value: appState?.user?.username },
              { code: 'gameProvider', graphqlType: 'String', required: true, value: slotState.selectedGameProvider?.code },
              { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
            ],
            attributes: []

          }])
            .then(({ data }) => {

              // mytab.location = gameUrl
              if (!isInAppBrowser) {
                mytab.location = gameUrl
              } else {
                setLoadingTransfer(false);
              }

              if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                setLoading(false);
                setLoadingTransfer(false);
                setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: gameSelectParams, walletAmount: gameWalletAmount });
              }

              else {
                if (isInAppBrowser) {
                  window.location.href = gameUrl;
                }
                queries([{
                  index: 'getAllGamePrivoderBalanceByMember',
                  method: 'getAllGamePrivoderBalanceByMember',
                  params: [],
                  attributes: []
                },
                {
                  index: 'member',
                  method: 'member',
                  params: [
                    { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                  ],
                  attributes: ['wallet_amount']
                },])
                  .then(({ data }) => {

                    setLoading(false);
                    setLoadingTransfer(false);
                    appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                    appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })


                  }).catch((error) => {
                    console.log('error: ', error)
                    // mytab.close()
                    if (!isInAppBrowser) {
                      mytab.close()
                    }
                    setLoading(false)
                    setLoadingTransfer(false);

                    if (error?.networkError?.statusCode === 401) {
                      appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                    } else {
                      appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                      });
                    }
                  })

              }

            })
            .catch((error) => {
              // mytab.close()
              if (!isInAppBrowser) {
                mytab.close()
              }
              console.log('error: ', error)
              setLoading(false)
              setLoadingTransfer(false);

              if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
              } else {
                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: error.message.toString(), typeAlert: 'error' }
                });
              }
            })
        }

      }).catch((error) => {
        // mytab.close()
        if (!isInAppBrowser) {
          mytab.close()
        }
        setLoading(false)
        setLoadingTransfer(false);

        console.log('error: ', error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
    } else {
      return appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: t('error_login_continue'), typeAlert: 'error' }
      });
    }
  }

  const onClickPlayNowApp = () => {
    setLoading(true);
    if (appState?.user?.auto_restore) {

      let gameSelectParams = {
        getCurrentGameProvider: slotState.selectedGameProvider?.code, getCurrentGameType: 'APP'
      }

      mutation([{
        method: 'restoreGameWalletByMember',
        params: [
          { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
          { code: 'gameProvider', graphqlType: 'String', required: true, value: slotState.selectedGameProvider?.code },
          { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
        ],
        attributes: []

      }])
        .then(({ data }) => {

          if (gameProviderDetails?.balance === 0 && data['restoreGameWalletByMember'] === 0) {
            setLoading(false);
            setTransferDialogShow({ show: true, balance: gameProviderDetails?.balance, gameSelect: gameSelectParams, walletAmount: gameProviderDetails?.wallet_amount });
          }

          else {
            queries([{
              index: 'getAllGamePrivoderBalanceByMember',
              method: 'getAllGamePrivoderBalanceByMember',
              params: [],
              attributes: []
            },
            {
              index: 'member',
              method: 'member',
              params: [
                { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
              ],
              attributes: ['wallet_amount']
            },])
              .then(({ data }) => {

                setLoading(false);

                appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })

                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: t('autoTransferOn_playNowApp_content', { "gameId": `<b>${gameProviderDetails?.username}</b>`, "gamePassword": `<b>${gameProviderDetails?.password}</b>` }), typeAlert: 'success' }
                });


              }).catch((error) => {
                console.log('error: ', error)
                setLoading(false)
                if (error?.networkError?.statusCode === 401) {
                  appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                  appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: error.message.toString(), typeAlert: 'error' }
                  });
                }
              })

          }

        })
        .catch((error) => {

          console.log('error: ', error)
          setLoading(false)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
    } else {
      setLoading(false);
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: t('autoTransferOff_playNowApp_content', { "gameId": `<b>${gameProviderDetails?.username}</b>`, "gamePassword": `<b>${gameProviderDetails?.password}</b>` }), typeAlert: 'success' }
      });
    }
  }
  // If Is App
  useEffect(() => {
    // mslotDispatch({ type: MSLOT_ACTION.SET_GAME_USER_DETAIL, payload: { id: '', game_provider: '', username: '-', password: '-'} })

    if (slotState.selectedGameProvider?.is_application) {
      let getGameLinkByMemberParams = []
      let gameSelectParams = []

      setGameProviderDetails({})
      if (appState.user?.username) {
        setLoading(true)

        getGameLinkByMemberParams = [
          { code: 'gameProvider', graphqlType: 'String', required: true, value: slotState.selectedGameProvider?.code },
          { code: 'gameType', graphqlType: 'String', required: true, value: 'APP' },
          { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
          { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
          { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
        ]

        gameSelectParams = {
          getCurrentGameProvider: slotState.selectedGameProvider?.code, getCurrentGameType: 'APP'
        }


        queries([{
          method: 'getGameLinkByMember',
          params: getGameLinkByMemberParams,
          attributes: []

        }]).then(({ data }) => {
          setGameProviderDetails(data['getGameLinkByMember'])
          const gameBalance = data['getGameLinkByMember']?.balance
          const gameWalletAmount = data['getGameLinkByMember'].wallet_amount

          // setLoading(false)
          if (appState.pageLoadAutoRestore) {
            mutation([{
              method: 'restoreGameWalletByMember',
              params: [
                { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
                { code: 'gameProvider', graphqlType: 'String', required: true, value: slotState.selectedGameProvider?.code },
                { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
              ],
              attributes: []

            }])
              .then(({ data }) => {

                if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                  setLoading(false);
                  setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: gameSelectParams, walletAmount: gameWalletAmount });
                }

                else {
                  queries([{
                    index: 'getAllGamePrivoderBalanceByMember',
                    method: 'getAllGamePrivoderBalanceByMember',
                    params: [],
                    attributes: []
                  },
                  {
                    index: 'member',
                    method: 'member',
                    params: [
                      { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                    ],
                    attributes: ['wallet_amount']
                  },])
                    .then(({ data }) => {

                      setLoading(false);

                      appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })


                    }).catch((error) => {
                      console.log('error: ', error)
                      setLoading(false)
                      if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                      } else {
                        appDispatch({
                          type: APP_STORE_ACTION.SHOW_ALERT,
                          payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                      }
                    })

                }

              })
              .catch((error) => {

                console.log('error: ', error)
                setLoading(false)
                if (error?.networkError?.statusCode === 401) {
                  appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                  appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: error.message.toString(), typeAlert: 'error' }
                  });
                }
              })
          }
          else {
            setLoading(false)
          }



        }).catch((error) => {
          setLoading(false)
          console.log('error: ', error)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
      } else {
        return appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: t('error_login_continue'), typeAlert: 'error' }
        });
      }

    }




  }, [queries, slotState.selectedGameProvider, appState.user?.username, t, appDispatch, mutation, navigate, i18n.resolvedLanguage, deviceType, appState.pageLoadAutoRestore])

  useEffect(() => {

    if (slotState.selectedGameProvider?.is_application || slotState.selectedGameProvider?.lobby_only) {

      // setLoading(true)
      queries([{
        method: 'getGameProviderTerms',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { language: i18n.resolvedLanguage, game_provider: slotState.selectedGameProvider?.code } } },
        ],
        attributes: ['id', 'content']

      }]).then(({ data }) => {
        // setLoading(false)
        mslotDispatch({ type: MSLOT_ACTION.SET_APP_TERMS, payload: data['getGameProviderTerms'][0]?.content })

      }).catch((error) => {
        // setLoading(false)
        console.log('error: ', error)
      })

    }

  }, [queries, i18n.resolvedLanguage, slotState.selectedGameProvider])

  // When Game Type Change
  useEffect(() => {
    setLoading(true)

    // FILTER ALL GAME BY GAME PROVIDER
    const gameByGameProvider = Object.values(slotState.allGames ?? "").filter(e => {
      return e.game_provider === slotState.selectedGameProvider?.code;
    });


    // DEFINE NEW (WITHIN 7 DAYS)
    const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;
    const now = Date.now();

    const processedData = gameByGameProvider.map(g => {
      const createdTime = new Date(g.created_at).getTime();
      const isNew = (now - createdTime) <= SEVEN_DAYS;
      return {
        ...g,
        isNew
      };
    });

    // SORT DATA (RANKING > FAVOURITE (RECOMMEND)> IS NEW)
    const sortedData = processedData.sort((a, b) => {

      if (a.ranking !== -1 && b.ranking === -1) return -1;
      if (a.ranking === -1 && b.ranking !== -1) return 1;
      if (a.ranking !== -1 && b.ranking !== -1) {
        return a.ranking - b.ranking;
      }


      if (a.favourite && !b.favourite) return -1;
      if (!a.favourite && b.favourite) return 1;


      if (a.isNew && !b.isNew) return -1;
      if (!a.isNew && b.isNew) return 1;


      return 0;
    });



    // const filterGameList = slotState.selectedGameType === "ALL" ? gameByGameProvider : gameByGameProvider.filter(({ sub_type }) => sub_type === slotState.selectedGameType);
    const filterGameList = (() => {
      if (slotState.selectedGameType === "ALL") {
        return sortedData;
      }

      if (slotState.selectedGameType === "NEW") {
        return sortedData.filter(({ isNew }) => isNew === true);
      }

      if (slotState.selectedGameType === "HOT") {
        return sortedData.filter(({ hot }) => hot === true);
      }

      return sortedData.filter(({ sub_type }) => sub_type === slotState.selectedGameType);
    })();

    // CHECK SORT GAMES
    if (slotState.selectedSortGames?.code === "a_z") {
      filterGameList.sort((a, b) => a?.name.localeCompare(b?.name));
    } else if (slotState.selectedSortGames?.code === "z_a") {
      filterGameList.sort((a, b) => b?.name.localeCompare(a?.name));
    }

    // setGame(filterGameList);
    console.log('filterGameList', filterGameList)
    slotDispatch({ type: SLOT_ACTION.SET_GAMES, payload: filterGameList })
    setLoading(false)

  }, [slotState.allGames, slotState.selectedGameProvider?.code, slotState.selectedGameType, slotState.selectedSortGames?.code])


  // When Search Input Change
  useEffect(() => {

    const gameByGameProvider = Object.values(slotState.allGames ?? "").filter(e => {
      return e.game_provider === slotState.selectedGameProvider?.code;
    });
    // const filterGameList = slotState.selectedGameType === "ALL" ? gameByGameProvider : gameByGameProvider.filter(({ sub_type }) => sub_type === slotState.selectedGameType);

    // DEFINE NEW (WITHIN 7 DAYS)
    const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;
    const now = Date.now();

    const processedData = gameByGameProvider.map(g => {
      const createdTime = new Date(g.created_at).getTime();
      const isNew = (now - createdTime) <= SEVEN_DAYS;
      return {
        ...g,
        isNew
      };
    });

    // SORT DATA (RANKING > FAVOURITE (RECOMMEND)> IS NEW)
    const sortedData = processedData.sort((a, b) => {

      if (a.ranking !== -1 && b.ranking === -1) return -1;
      if (a.ranking === -1 && b.ranking !== -1) return 1;
      if (a.ranking !== -1 && b.ranking !== -1) {
        return a.ranking - b.ranking;
      }


      if (a.favourite && !b.favourite) return -1;
      if (!a.favourite && b.favourite) return 1;


      if (a.isNew && !b.isNew) return -1;
      if (!a.isNew && b.isNew) return 1;


      return 0;
    });

    const filterGameList = (() => {
      if (slotState.selectedGameType === "ALL") {
        return sortedData;
      }

      if (slotState.selectedGameType === "NEW") {
        return sortedData.filter(({ isNew }) => isNew === true);
      }

      if (slotState.selectedGameType === "HOT") {
        return sortedData.filter(({ hot }) => hot === true);
      }

      return sortedData.filter(({ sub_type }) => sub_type === slotState.selectedGameType);
    })();

    // CHECK SORT GAMES
    if (slotState.selectedSortGames?.code === "a_z") {
      filterGameList.sort((a, b) => a?.name.localeCompare(b?.name));
    } else if (slotState.selectedSortGames?.code === "z_a") {
      filterGameList.sort((a, b) => b?.name.localeCompare(a?.name));
    }

    if (slotState.searchInput !== '') {
      const filteredData = filterGameList.filter((item) => {
        return Object.values(item.name).join('').toLowerCase().includes(slotState.searchInput.toLowerCase())
      })

      slotDispatch({ type: SLOT_ACTION.SET_FILTER_GAMES, payload: filteredData })
      // setFilteredResults(filteredData)
    }
    else {
      slotDispatch({ type: SLOT_ACTION.SET_FILTER_GAMES, payload: filterGameList })
      // setFilteredResults(filterGameList)
    }

  }, [slotState.searchInput, slotState.selectedGameType, slotState.allGames, slotState.selectedGameProvider?.code, slotState.selectedSortGames?.code])


  const swiperRef = useRef(null);

  const handleButtonClick = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <section id="slot_01">
      <div className="slots_body">
        {/* <PageTitle desktopToggle={false} mobileToggle={true} title={slotState.selectedGameProvider?.name} id={'slot_title'} /> */}
        {!isDesktopOrLaptop && (<section className="section pageTitle font_h2" id={`slot_title`}><Container className={` title_container d-lg-none`} >
          <Link onClick={() => navigate(-1)}><BiArrowBack /></Link>
          <div>
            <Form.Select value={slotState.selectedGameProvider?.code} onChange={(evt) => { onChangeGameProvider(evt.target.value) }}>

              {slotState.gameProviders?.map(function (item, index) {
                return (
                  <option value={item.code} key={item.code}>{t(item.name)}</option>
                )
              })}
            </Form.Select>
          </div>
        </Container></section>)}
        <Container>
          {isDesktopOrLaptop && <div className="gameProviderTab_container">
            <button onClick={() => swiperRef.current.slidePrev()}>◀</button>
            <Swiper
              ref={swiperRef}
              // navigation
              spaceBetween={5}
              slidesPerView={8}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}

              breakpoints={{

                992: {
                  // width: 1000,
                  slidesPerView: 6,
                },

                1200: {
                  // width: 1000,
                  slidesPerView: 7,
                },

                1400: {
                  // width: 1000,
                  slidesPerView: 8,
                },


              }}

              className="gameProviderTab"
            >
              {
                slotState.gameProviders?.map(function (item, index) {
                  // item = appState.gameProviders[item] ?? ""

                  return (
                    <SwiperSlide key={item.code}>
                      {(item.hot) && (
                        <div className="gameProvider_hot_icon">
                          <img src={`${TENANT_BUCKET}/icon/hot_icon.png`} alt={'hot'} />
                        </div>
                      )}
                      <Link to={`/slots#${item.code}`} className={`gameProviderTab_selection ${item.code === slotState.selectedGameProvider?.code ? 'color_active active' : ''}`}>
                        <img src={gameProviderFolderImage !== '' ? `${TENANT_BUCKET}/game-provider/${gameProviderFolderImage}/${item.code}.png` : `${GLOBAL_BUCKET}/game_provider_logo/${item.code}.png`} alt="game icon" />
                        {gameProviderNameToggle && (t(item.name))}
                      </Link>
                    </SwiperSlide>
                  )
                })}
            </Swiper>
            <button onClick={handleButtonClick}>▶</button>
          </div>}
          <>
            {(!slotState.selectedGameProvider?.is_application && !slotState.selectedGameProvider?.lobby_only) ?
              <>
                <div className="slotType_container">
                  <Nav variant="pills" className="slotTypeTab" defaultActiveKey={'all'}>
                    {slotState.gameTypes?.map(function (item, index) {
                      item = SLOT_GAME_TYPE[item] ?? ""
                      let _item = slotState.allSubGameTypes.find(e => e.code === item.gameType) ?? ""
                      return (
                        <Nav.Item key={index}>
                          <div className={`slotTypeTab_selection ${slotState.selectedGameType === item.gameType ? 'color_active active' : ''}`} onClick={() => onChangeGameType(item.gameType)}>
                            {/* {t(_item?.name)} */}
                            {item.gameType === 'ALL' ? t('all') : t(`SUB_GAME_TYPE_${_item.code ?? item.gameType}`)}
                          </div>
                        </Nav.Item>
                      )
                    })}
                  </Nav>
                  <div className="sort_container">
                    <div className="sortGames_container">
                      <Form.Select aria-label="sortGames" className="input_sortGames" value={slotState.selectedSortGames?.code} onChange={(evt) => { onChangeSortGames(evt.target.value) }}>
                        {slotState.sortGames?.map(function (item, index) {
                          return (
                            <option value={item.code} key={item.code}>{t(item.name)}</option>
                          )
                        })}
                      </Form.Select>
                    </div>
                    <div className="search_container">
                      <Form.Control
                        type="text"
                        name="Search"
                        placeholder={t('search')}
                        className="input_search"
                        aria-label="Search"
                        onChange={(e) => onChangeSearchInput(e.target.value)}
                        value={slotState.searchInput ?? ''}
                      />
                      <BiSearch />
                    </div>
                  </div>
                </div>

                <SlotGameContainer desktopToggle={true} mobileToggle={true} imageSize={imageSize !== "" ? imageSize : '1x2'} contentPosition={contentPosition ?? 'bottom'} actionPosition={'hover'} id={'slotGameContainer'} rowDesktop={rowDesktop ?? 5} rowMobile={rowMobile ?? 3} numberOfRow={-1} dataType={'games'} cardPosition={"start"} data={slotState.searchInput.length > 0 ? slotState.filterGames : slotState.games} filter={slotState?.selectedGameType} /></> :
              ((slotState.selectedGameProvider?.is_application || slotState.selectedGameProvider?.lobby_only) ? <>
                <div className={`mslot_container ${!isDesktopOrLaptop ? 'mslot_container_m' : ''}`}>
                  <div className={`mslot_wrap`}>
                    <div className="mslot_imageWrap">

                      <img src={`${TENANT_BUCKET}/game-provider/${appFolderImage !== '' ? `${appFolderImage}/` : `dropdown/`}SL/${slotState.selectedGameProvider?.code}.png`} alt="game icon" />
                    </div>
                    {((slotState.selectedGameProvider?.is_application || slotState.selectedGameProvider?.lobby_only) && !isDesktopOrLaptop) && (<div className="mslot_title">
                      <span>{t('your_access_information')}</span>
                    </div>)}
                    <div className="mslot_contentWrap">
                      {isDesktopOrLaptop && (<div className="mslot_title">
                        <span>{t('your_access_information')}</span>
                        <AiFillExclamationCircle onClick={() => setShowAppTerm(true)} />
                      </div>)}
                      {slotState.selectedGameProvider?.is_application && (<div className="mslot_userInfo">
                        <div className="mslot_userInfo_row">
                          <label>{t('username')}</label>
                          <input type={'text'} value={gameProviderDetails?.username ?? '-'} disabled />
                          <AiOutlineCopy onClick={() => copyText(gameProviderDetails?.username)} />
                        </div>
                        <div className="mslot_userInfo_row">
                          <label>{t('password')}</label>
                          <input type={'text'} value={gameProviderDetails?.password ?? '-'} disabled />
                          <AiOutlineCopy onClick={() => copyText(gameProviderDetails?.password)} />
                        </div>
                      </div>)}

                      <div className={`mslot_downloadButton`}>
                        {
                          gameProviderDetails?.android_download_link &&
                          (<a href={gameProviderDetails?.android_download_link} target="_blank" rel="noreferrer"><img src={`${TENANT_BUCKET}/icon/APP_android.png`} alt="" /></a>)
                        }
                        {
                          gameProviderDetails?.ios_download_link &&
                          (<a href={gameProviderDetails?.ios_download_link} target="_blank" rel="noreferrer"><img src={`${TENANT_BUCKET}/icon/APP_ios.png`} alt="" /></a>)
                        }
                        {
                          gameProviderDetails?.window_download_link &&
                          (<a href={gameProviderDetails?.window_download_link} target="_blank" rel="noreferrer"><img src={`${TENANT_BUCKET}/icon/APP_window.png`} alt="" /></a>)
                        }
                        {/* {
                          (gameProviderDetails?.gameLink && slotState.selectedGameProvider?.lobby_only) &&
                          (<a href={gameProviderDetails?.gameLink} target="_blank" rel="noreferrer"><img src={`${TENANT_BUCKET}/icon/APP_playNow_${i18n.resolvedLanguage}.png`} alt="" /></a>)
                        } */}

                        {
                          (slotState.selectedGameProvider?.lobby_only) &&
                          (<img src={`${TENANT_BUCKET}/icon/APP_playNow_${i18n.resolvedLanguage}.png`} alt="" onClick={onClickPlayNowLobby} />)
                        }

                        {
                          (slotState.selectedGameProvider?.is_application && !appState.pageLoadAutoRestore) &&
                          (<div><img src={`${TENANT_BUCKET}/icon/APP_playNow_${i18n.resolvedLanguage}.png`} alt="" onClick={onClickPlayNowApp} className="appPlayNow_img" /></div>)
                        }
                      </div>
                      {!isDesktopOrLaptop && (<div className="mslot_terms">
                        <div className="reminder_information_content" dangerouslySetInnerHTML={{ __html: mslotState.appTerms }}></div>
                      </div>)}
                    </div>
                  </div>
                </div>
                <Modal
                  show={showAppTerm}
                  onHide={() => setShowAppTerm(false)}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  id="GameTermsModal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="subTitle">
                      {t('reminder_information')}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="reminder_information_content" dangerouslySetInnerHTML={{ __html: mslotState.appTerms }}></div>
                  </Modal.Body>
                </Modal>

                <TransferBalanceDialog show={transferDialogShow.show} onHide={() => setTransferDialogShow({ ...transferDialogShow, show: false })} balance={transferDialogShow.balance} gameSelect={transferDialogShow.gameSelect} walletAmount={transferDialogShow.walletAmount} />
              </> : <></>
              )
            }

            {isLoading && (<Loading />)}
            {isLoadingTransfer && <Loading message={'Transferring funds to the game ...'} />}
          </>
        </Container>
      </div>

    </section>
  );
}

export default Slot;
